<template>
  <div class="earncontent">
    <div class="header">
      <div class="headercontent">
        <div class="left">
          <img @click="clickBack" class="backicon" :src="require('@/assets/images/mall/goods/shopback.png')" />
          <div class="headertitle">商家后台</div>
        </div>
      </div>
      <div class="sellbox">
        <div class="sellnum" v-for="(i, index) in info" :key="index">
          <div class="num">{{ parseFloat(i.user_moneys).toFixed(2) }}</div>
          <div class="txt">{{ i.coin_symbol }}</div>
        </div>
      </div>
      <div class="earninfo">
        <div class="earnnum">
          <div class="num">{{ todayProfit }}</div>
          <div class="txt">今日可用收益/USDT</div>
        </div>
        <div class="earnnum">
          <div class="num">{{ todayWeiProfit }}</div>
          <div class="txt">今日待结算收益/USDT</div>
        </div>
      </div>
    </div>
    <div class="earntab">
      <van-tabs v-model="tabvalue" @change="changeTab">
        <div v-for="tab in tabs" :key="tab">
          <van-tab :title="tab">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div class="cellbox">
                  <div v-for="(item, key, index) in plist" :key="index">
                    <div class="date">{{ key }}</div>
                    <div class="cell" v-for="(subitem, num) in item" :key="num">
                      <div class="info">
                        <div class="num">{{ parseFloat(subitem.money).toFixed(2) }}/{{ subitem.coin_symbol }}</div>
                        <div class="time">{{ subitem.add_time }}</div>
                        <div class="status">{{ subitem.desc }}</div>
                      </div>
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
              </van-list>
            </van-pull-refresh>
          </van-tab>
        </div>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { profitData, profitList } from "@/request/storeapi";
export default {
  data() {
    return {
      tabs: ["ATT", "ARR", "EPT"],
      tabvalue: 0,
      info: [],
      todayProfit: 0,
      todayWeiProfit: 0,
      plist: {},
      currentpage: 1,
      coin: "ATT",
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  activated() {
    this.requestprofitData();
    this.onRefresh();
  },
  methods: {
    changeTab(value) {
      this.onRefresh();
    },
    requestprofitData() {
      profitData().then((res) => {
        this.info = res.data.info;
        this.todayProfit = res.data.todayProfit;
        this.todayWeiProfit = res.data.todayWeiProfit;
      });
    },
    requestprofitList() {
      if (this.tabvalue === 0) {
        this.coin = "ATT";
      } else if (this.tabvalue === 1) {
        this.coin = "ARR";
      } else {
        this.coin = "EPT";
      }
      const params = {
        page: this.currentpage,
        limit: 20,
        coin_symbol: "ATT",
      };
      profitList(params).then((res) => {
        if (this.refreshing) {
          this.refreshing = false;
        }
        for (let key in res.data.list) {
          let item = res.data.list[key];
          this.$set(this.plist, key, item);
        }
        // this.plist = [...res.data.list];

        this.plist = Object.assign({}, this.plist);

        this.loading = false;
        let arr = Object.keys(res.data.list);
        let len = arr.length;
        console.log(this.plist);
        if (len < 20) {
          this.finished = true;
        }
      });
    },
    clickBack() {
      this.$router.go(-1);
    },
    onLoad() {
      this.requestprofitList();
        // this.currentpage++;
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.currentpage = 1;
      this.plist = {};
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style scoped lang="scss">
.earncontent {
  min-height: 100vh;
  background: rgba(235, 238, 242, 1);
  .earntab {
    padding: 24px;
    ::v-deep .van-tabs__nav {
      background-color: transparent;
    }
    ::v-deep .van-tabs__line {
      width: 28px;
      height: 4px;
      border-radius: 4px;
      opacity: 1;
      background: rgba(255, 114, 32, 1);
      bottom: 20px;
    }
    .cellbox {
      padding: 10px 24px 24px 24px;
      width: 702px;
      min-height: 874px;
      border-radius: 24px;
      opacity: 1;
      background: rgba(255, 255, 255, 1);
      .date {
        color: rgba(105, 98, 95, 1);
        font-weight: 700;
        text-align: left;
        font-size: 28px;
        font-family: "PingFang SC";
        line-height: 40px;
        margin-top: 24px;
      }
      .cell {
        height: 96px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 93px;
          .num {
            color: rgba(41, 33, 29, 1);
            font-weight: 700;
            text-align: center;
            font-size: 32px;
            font-family: "PingFang SC";
          }
          .time {
            color: rgba(105, 98, 95, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 36px;
          }
          .status {
            color: rgba(39, 204, 127, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: left;
            line-height: 36px;
          }
        }
        .line {
          width: 656px;
          height: 2px;
          border-radius: 2px;
          opacity: 1;
          background: rgba(235, 238, 242, 1);
        }
      }
    }
  }
}
.header {
  width: 750px;
  height: 480px;
  opacity: 1;
  background: url("../../assets/images/mall/goods/shopbg.png");
  background-size: 100% 100%;
  .headercontent {
    padding: 88px 24px 0px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      .backicon {
        width: 64px;
        height: 64px;
        margin-right: 16px;
      }
      .headertitle {
        color: white;
        font-size: 32px;
        font-weight: 700;
        font-family: "PingFang SC";
      }
    }
  }
  .sellbox {
    margin-top: 28px;
    width: 686px;
    height: 196px;
    border-radius: 24px;
    opacity: 1;
    background: rgba(255, 247, 242, 1);
    margin-left: 32px;
    padding: 24px 0px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    .sellnum {
      width: 228px;
      text-align: center;
      .num {
        color: rgba(41, 33, 29, 1);
        font-size: 28px;
        font-weight: 500;
        font-family: "DIN";
        text-align: center;
        line-height: 40px;
      }
      .txt {
        color: rgba(156, 152, 150, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 36px;
      }
    }
  }
  .earninfo {
    position: relative;
    top: -80px;
    margin-left: 24px;
    width: 702px;
    height: 128px;
    border-radius: 0 0 24px 24px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    .earnnum {
      .num {
        color: rgba(255, 114, 32, 1);
        font-weight: 500;
        font-family: "DIN";
        text-align: center;
        line-height: 44px;
        font-size: 36px;
      }
      .txt {
        color: rgba(156, 152, 150, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 36px;
      }
    }
  }
}
</style>
